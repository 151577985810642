import React, { useContext } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Header, Heading, ResponsiveContext } from "grommet";

interface Props {
  title: string;
  full?: boolean;
  onHide?: () => void;
}

const LayerHeader: React.FC<Props> = ({ title, onHide, full }) => {
  const responsive = useContext(ResponsiveContext);
  const small = responsive === "small";
  return (
    <Box
      width={full || small ? "auto" : "768px"}
      direction="row"
      justify="between"
      align="center"
      pad="medium"
    >
      <Box>
        <Heading level={3} margin="0">
          {title}
        </Heading>
      </Box>
      {onHide && (
        <Box>
          <Button
            hoverIndicator
            onClick={onHide}
            icon={<FontAwesomeIcon icon={faTimes} />}
          />
        </Box>
      )}
    </Box>
  );
};

export default LayerHeader;
