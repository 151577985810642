import React, { useContext } from "react";
import { format } from "date-fns";
import { Box, Button, ButtonType, ResponsiveContext, Text } from "grommet";
import RatingFace from "../RatingFace";

import Map from "src/models/Map";

interface Props extends ButtonType {
  map: Map;
}

const MapListItem: React.FC<Props> = ({ map, ...props }) => {
  const responsive = useContext(ResponsiveContext);
  return (
    <Button
      margin={{ bottom: "small" }}
      key={map.id}
      color="light-5"
      style={{ background: "white" }}
      label={
        <Box
          justify="between"
          direction="row"
          pad={{ vertical: "xsmall" }}
          gap="small"
        >
          <Box direction="row" align="center">
            {/* <div style={{ width: 56 }}>
        <PlaceImage src={place.img} fit="cover" />
      </div> */}
            <Box align="start">
              <Box>
                <Text textAlign="start" size={responsive} truncate>
                  {map.title}
                </Text>
              </Box>
              <Box>
                <Text textAlign="start" size="xsmall" color="dark-5" truncate>
                  {map.address} -{" "}
                  {format(map.created.seconds * 1000, "hh:mm a - dd/MM/yyyy")}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box align="center" justify="center" direction="row">
            <Box></Box>
            <RatingFace value={4} style={{ height: 16 }} />
          </Box>
        </Box>
      }
      {...props}
    />
  );
};

export default MapListItem;
