import React, { useContext } from "react";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, FormField, Heading, Text, TextInput } from "grommet";
import { AppContext } from "../context/AppContext";
import EmptyState from "../EmptyState";
import LoadingBox from "../LoadingBox";
import UserMaps from "./UserMaps";

import Page from "src/components/Page";

const UserProfilePage: React.FC = () => {
  const { profile, profileLoading, setShowAuthLayer } = useContext(AppContext);

  if (profileLoading) {
    return (
      <Page topNavRight={<></>}>
        <LoadingBox text="Loading profile" />
      </Page>
    );
  }

  if (!profile) {
    return (
      <Page title="Profile not found" topNavRight={<></>}>
        <EmptyState
          title="Profile not found"
          subtitle="We can't find that profile you are looking for"
        />
      </Page>
    );
  }

  const { username } = profile;

  return (
    <Page title="User profile">
      <Box pad="medium">
        <FormField label="Username">
          <TextInput value={username} disabled />
        </FormField>
        {profile.isAnonymous && (
          <Box background="light-3" pad={{ vertical: "medium" }} align="center">
            <Text margin={{ bottom: "small" }}>
              Complete your registration to claim your username
            </Text>
            <Button
              color="brand"
              label="Complete registration"
              icon={<FontAwesomeIcon icon={faUserPlus} />}
              onClick={() => setShowAuthLayer(true)}
            />
          </Box>
        )}
        <Heading level={3}>My maps</Heading>
        <Box>
          <UserMaps userId={profile.id} />
        </Box>
      </Box>
    </Page>
  );
};

export default UserProfilePage;
