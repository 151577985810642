import React, { useContext } from "react";
import { Box, Text } from "grommet";
import { useCollectionData } from "react-firebase-hooks/firestore";
import FlexLink from "../Link";

import LoadingBox from "src/components/LoadingBox";
import MapListItem from "src/components/map/MapListItem";

import Map from "src/models/Map";

import { mapsRef } from "src/lib/dartmap/refs";

interface Props {
  userId: string;
}

const UserMaps: React.FC<Props> = ({ userId }) => {
  const mapsQuery = mapsRef().where("uid", "==", userId);
  const [maps, mapsLoading] = useCollectionData<Map>(mapsQuery);

  if (mapsLoading) {
    return <LoadingBox text="Loading maps" />;
  }

  if (!maps || maps.length < 1) {
    return <Text>No maps found</Text>;
  }

  return (
    <Box flex>
      {maps.map(map => (
        <FlexLink key={map.id} to={`/map/${map.id}`}>
          <MapListItem map={map} />
        </FlexLink>
      ))}
    </Box>
  );
};

export default UserMaps;
