import firebase from "firebase/app";

import { firestore } from "src/lib/firebase";

const COL_MAPS = "maps";
const COL_MAP_PLACES = "map_places";
const COL_MAP_PLACE_REVIEWS = "map_place_reviews";

const COL_SUGGESTIONS = "suggestions";

const COL_PLACES = "places";

const COL_USERS = "users";

const COL_PROFILES = "profiles";

const COL_PARTNERS = "partners";

export const increment = firebase.firestore.FieldValue.increment;
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

/**
 * Maps collection
 */
export const mapsRef = () => firestore.collection(COL_MAPS);
export const mapRef = (mapId: string) => mapsRef().doc(mapId);

export const mapPlacesRef = (mapId: string) =>
  mapRef(mapId).collection(COL_MAP_PLACES);

export const mapPlaceRef = (mapId: string, placeId: string) =>
  mapPlacesRef(mapId).doc(placeId);

export const mapPlaceReviewsRef = (mapId: string, placeId: string) =>
  mapPlaceRef(mapId, placeId).collection(COL_MAP_PLACE_REVIEWS);

export const mapPlaceReviewRef = (
  mapId: string,
  placeId: string,
  reviewId: string
) => mapPlaceReviewsRef(mapId, placeId).doc(reviewId);

/**
 * Suggestions collection
 */
export const suggestionsRef = () => firestore.collection(COL_SUGGESTIONS);
export const suggestionRef = (suggestionId: string) =>
  suggestionsRef().doc(suggestionId);

export const suggestionRefId = (mapId: string, uid: string, placeId: string) =>
  `${mapId}_${uid}_${placeId}`;

/**
 * Users collection
 */

export const usersRef = () => firestore.collection(COL_USERS);
export const userRef = (userId: string) => usersRef().doc(userId);

export const profilesRef = () => firestore.collection(COL_PROFILES);
export const profileRef = (userId: string) => profilesRef().doc(userId);

/**
 * Partners collection
 */

export const partnersRef = () => firestore.collection(COL_PARTNERS);
export const partnerRef = (partnerId: string) => partnersRef().doc(partnerId);

/**
 * Places ref
 */

export const placesRef = () => firestore.collection(COL_PLACES);
export const placeRef = (placeId: string) => placesRef().doc(placeId);
