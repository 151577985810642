import React, { CSSProperties } from "react";
import { Image } from "grommet";
import styled from "styled-components";

import RatingFace1Svg from "src/assets/ratingFace1.svg";
import RatingFace2Svg from "src/assets/ratingFace2.svg";
import RatingFace3Svg from "src/assets/ratingFace3.svg";
import RatingFace4Svg from "src/assets/ratingFace4.svg";
import RatingFace5Svg from "src/assets/ratingFace5.svg";

type OnClick = (value: number) => void;

interface RatingImgProps {
  selected: boolean;
  onClick: OnClick;
}
const RatingImg = styled(Image)<RatingImgProps>`
  opacity: ${({ onClick, selected }) =>
    onClick ? (selected ? 100 : 20) : 100}%;
  cursor: pointer;
  border-radius: 50%;
  border: 2px transparent solid;
  transition: 100ms linear;
  &:hover {
    opacity: ${({ onClick, selected }) =>
      onClick ? (selected ? 100 : 50) : 100}%;
  }
  &:active {
    opacity: ${({ onClick }) => (onClick ? 20 : 100)}%;
  }
`;

let ratingFaceSvg = [
  RatingFace1Svg,
  RatingFace2Svg,
  RatingFace3Svg,
  RatingFace4Svg,
  RatingFace5Svg
];
interface Props {
  onClick?: OnClick;
  maxWidth?: number;
  selected?: boolean;
  style?: CSSProperties;
  value: number;
}
const RatingFace: React.FC<Props> = ({
  onClick,
  selected = true,
  style,
  value
}) => {
  const onImgClick = () => {
    onClick && onClick(value);
  };
  return (
    <RatingImg
      src={ratingFaceSvg[value - 1]}
      selected={selected}
      onClick={onImgClick}
      style={style}
    />
  );
};

export default RatingFace;
