import React from "react";
import { Box, Button, DropButton } from "grommet";
import PartnerStatus from "./PartnerStatus";

import { partnerRef } from "src/lib/dartmap/refs";

const statuses = ["new", "contacted", "pending", "partner", "closed"];

interface PartnerStatusButtonProps {
  partnerId: string;
  status: string;
}

const PartnerStatusButton: React.FC<PartnerStatusButtonProps> = ({
  partnerId,
  status
}) => {
  const updateStatus = async (status: string) => {
    await partnerRef(partnerId).set({ status }, { merge: true });
  };

  return (
    <DropButton
      plain
      fill
      label={<PartnerStatus status={status} />}
      dropAlign={{ top: "bottom", right: "right" }}
      dropContent={
        <Box pad="small">
          {statuses.map(status => (
            <Box pad="xsmall">
              <Button
                plain
                label={<PartnerStatus status={status} />}
                onClick={() => updateStatus(status)}
              />
            </Box>
          ))}
        </Box>
      }
    />
  );
};

export default PartnerStatusButton;
