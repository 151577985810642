const countryCodes = [
  {
    n: "Afghanistan",
    p: "+93",
    c: "AF"
  },
  {
    n: "Aland Islands",
    p: "+358",
    c: "AX"
  },
  {
    n: "Albania",
    p: "+355",
    c: "AL"
  },
  {
    n: "Algeria",
    p: "+213",
    c: "DZ"
  },
  {
    n: "AmericanSamoa",
    p: "+1684",
    c: "AS"
  },
  {
    n: "Andorra",
    p: "+376",
    c: "AD"
  },
  {
    n: "Angola",
    p: "+244",
    c: "AO"
  },
  {
    n: "Anguilla",
    p: "+1264",
    c: "AI"
  },
  {
    n: "Antarctica",
    p: "+672",
    c: "AQ"
  },
  {
    n: "Antigua and Barbuda",
    p: "+1268",
    c: "AG"
  },
  {
    n: "Argentina",
    p: "+54",
    c: "AR"
  },
  {
    n: "Armenia",
    p: "+374",
    c: "AM"
  },
  {
    n: "Aruba",
    p: "+297",
    c: "AW"
  },
  {
    n: "Australia",
    p: "+61",
    c: "AU"
  },
  {
    n: "Austria",
    p: "+43",
    c: "AT"
  },
  {
    n: "Azerbaijan",
    p: "+994",
    c: "AZ"
  },
  {
    n: "Bahamas",
    p: "+1242",
    c: "BS"
  },
  {
    n: "Bahrain",
    p: "+973",
    c: "BH"
  },
  {
    n: "Bangladesh",
    p: "+880",
    c: "BD"
  },
  {
    n: "Barbados",
    p: "+1246",
    c: "BB"
  },
  {
    n: "Belarus",
    p: "+375",
    c: "BY"
  },
  {
    n: "Belgium",
    p: "+32",
    c: "BE"
  },
  {
    n: "Belize",
    p: "+501",
    c: "BZ"
  },
  {
    n: "Benin",
    p: "+229",
    c: "BJ"
  },
  {
    n: "Bermuda",
    p: "+1441",
    c: "BM"
  },
  {
    n: "Bhutan",
    p: "+975",
    c: "BT"
  },
  {
    n: "Bolivia, Plurinational State of",
    p: "+591",
    c: "BO"
  },
  {
    n: "Bosnia and Herzegovina",
    p: "+387",
    c: "BA"
  },
  {
    n: "Botswana",
    p: "+267",
    c: "BW"
  },
  {
    n: "Brazil",
    p: "+55",
    c: "BR"
  },
  {
    n: "British Indian Ocean Territory",
    p: "+246",
    c: "IO"
  },
  {
    n: "Brunei Darussalam",
    p: "+673",
    c: "BN"
  },
  {
    n: "Bulgaria",
    p: "+359",
    c: "BG"
  },
  {
    n: "Burkina Faso",
    p: "+226",
    c: "BF"
  },
  {
    n: "Burundi",
    p: "+257",
    c: "BI"
  },
  {
    n: "Cambodia",
    p: "+855",
    c: "KH"
  },
  {
    n: "Cameroon",
    p: "+237",
    c: "CM"
  },
  {
    n: "Canada",
    p: "+1",
    c: "CA"
  },
  {
    n: "Cape Verde",
    p: "+238",
    c: "CV"
  },
  {
    n: "Cayman Islands",
    p: "+ 345",
    c: "KY"
  },
  {
    n: "Central African Republic",
    p: "+236",
    c: "CF"
  },
  {
    n: "Chad",
    p: "+235",
    c: "TD"
  },
  {
    n: "Chile",
    p: "+56",
    c: "CL"
  },
  {
    n: "China",
    p: "+86",
    c: "CN"
  },
  {
    n: "Christmas Island",
    p: "+61",
    c: "CX"
  },
  {
    n: "Cocos (Keeling) Islands",
    p: "+61",
    c: "CC"
  },
  {
    n: "Colombia",
    p: "+57",
    c: "CO"
  },
  {
    n: "Comoros",
    p: "+269",
    c: "KM"
  },
  {
    n: "Congo",
    p: "+242",
    c: "CG"
  },
  {
    n: "Congo, The Democratic Republic of the Congo",
    p: "+243",
    c: "CD"
  },
  {
    n: "Cook Islands",
    p: "+682",
    c: "CK"
  },
  {
    n: "Costa Rica",
    p: "+506",
    c: "CR"
  },
  {
    n: "Cote d'Ivoire",
    p: "+225",
    c: "CI"
  },
  {
    n: "Croatia",
    p: "+385",
    c: "HR"
  },
  {
    n: "Cuba",
    p: "+53",
    c: "CU"
  },
  {
    n: "Cyprus",
    p: "+357",
    c: "CY"
  },
  {
    n: "Czech Republic",
    p: "+420",
    c: "CZ"
  },
  {
    n: "Denmark",
    p: "+45",
    c: "DK"
  },
  {
    n: "Djibouti",
    p: "+253",
    c: "DJ"
  },
  {
    n: "Dominica",
    p: "+1767",
    c: "DM"
  },
  {
    n: "Dominican Republic",
    p: "+1849",
    c: "DO"
  },
  {
    n: "Ecuador",
    p: "+593",
    c: "EC"
  },
  {
    n: "Egypt",
    p: "+20",
    c: "EG"
  },
  {
    n: "El Salvador",
    p: "+503",
    c: "SV"
  },
  {
    n: "Equatorial Guinea",
    p: "+240",
    c: "GQ"
  },
  {
    n: "Eritrea",
    p: "+291",
    c: "ER"
  },
  {
    n: "Estonia",
    p: "+372",
    c: "EE"
  },
  {
    n: "Ethiopia",
    p: "+251",
    c: "ET"
  },
  {
    n: "Falkland Islands (Malvinas)",
    p: "+500",
    c: "FK"
  },
  {
    n: "Faroe Islands",
    p: "+298",
    c: "FO"
  },
  {
    n: "Fiji",
    p: "+679",
    c: "FJ"
  },
  {
    n: "Finland",
    p: "+358",
    c: "FI"
  },
  {
    n: "France",
    p: "+33",
    c: "FR"
  },
  {
    n: "French Guiana",
    p: "+594",
    c: "GF"
  },
  {
    n: "French Polynesia",
    p: "+689",
    c: "PF"
  },
  {
    n: "Gabon",
    p: "+241",
    c: "GA"
  },
  {
    n: "Gambia",
    p: "+220",
    c: "GM"
  },
  {
    n: "Georgia",
    p: "+995",
    c: "GE"
  },
  {
    n: "Germany",
    p: "+49",
    c: "DE"
  },
  {
    n: "Ghana",
    p: "+233",
    c: "GH"
  },
  {
    n: "Gibraltar",
    p: "+350",
    c: "GI"
  },
  {
    n: "Greece",
    p: "+30",
    c: "GR"
  },
  {
    n: "Greenland",
    p: "+299",
    c: "GL"
  },
  {
    n: "Grenada",
    p: "+1473",
    c: "GD"
  },
  {
    n: "Guadeloupe",
    p: "+590",
    c: "GP"
  },
  {
    n: "Guam",
    p: "+1671",
    c: "GU"
  },
  {
    n: "Guatemala",
    p: "+502",
    c: "GT"
  },
  {
    n: "Guernsey",
    p: "+44",
    c: "GG"
  },
  {
    n: "Guinea",
    p: "+224",
    c: "GN"
  },
  {
    n: "Guinea-Bissau",
    p: "+245",
    c: "GW"
  },
  {
    n: "Guyana",
    p: "+595",
    c: "GY"
  },
  {
    n: "Haiti",
    p: "+509",
    c: "HT"
  },
  {
    n: "Holy See (Vatican City State)",
    p: "+379",
    c: "VA"
  },
  {
    n: "Honduras",
    p: "+504",
    c: "HN"
  },
  {
    n: "Hong Kong",
    p: "+852",
    c: "HK"
  },
  {
    n: "Hungary",
    p: "+36",
    c: "HU"
  },
  {
    n: "Iceland",
    p: "+354",
    c: "IS"
  },
  {
    n: "India",
    p: "+91",
    c: "IN"
  },
  {
    n: "Indonesia",
    p: "+62",
    c: "ID"
  },
  {
    n: "Iran, Islamic Republic of Persian Gulf",
    p: "+98",
    c: "IR"
  },
  {
    n: "Iraq",
    p: "+964",
    c: "IQ"
  },
  {
    n: "Ireland",
    p: "+353",
    c: "IE"
  },
  {
    n: "Isle of Man",
    p: "+44",
    c: "IM"
  },
  {
    n: "Israel",
    p: "+972",
    c: "IL"
  },
  {
    n: "Italy",
    p: "+39",
    c: "IT"
  },
  {
    n: "Jamaica",
    p: "+1876",
    c: "JM"
  },
  {
    n: "Japan",
    p: "+81",
    c: "JP"
  },
  {
    n: "Jersey",
    p: "+44",
    c: "JE"
  },
  {
    n: "Jordan",
    p: "+962",
    c: "JO"
  },
  {
    n: "Kazakhstan",
    p: "+77",
    c: "KZ"
  },
  {
    n: "Kenya",
    p: "+254",
    c: "KE"
  },
  {
    n: "Kiribati",
    p: "+686",
    c: "KI"
  },
  {
    n: "Korea, Democratic People's Republic of Korea",
    p: "+850",
    c: "KP"
  },
  {
    n: "Korea, Republic of South Korea",
    p: "+82",
    c: "KR"
  },
  {
    n: "Kuwait",
    p: "+965",
    c: "KW"
  },
  {
    n: "Kyrgyzstan",
    p: "+996",
    c: "KG"
  },
  {
    n: "Laos",
    p: "+856",
    c: "LA"
  },
  {
    n: "Latvia",
    p: "+371",
    c: "LV"
  },
  {
    n: "Lebanon",
    p: "+961",
    c: "LB"
  },
  {
    n: "Lesotho",
    p: "+266",
    c: "LS"
  },
  {
    n: "Liberia",
    p: "+231",
    c: "LR"
  },
  {
    n: "Libyan Arab Jamahiriya",
    p: "+218",
    c: "LY"
  },
  {
    n: "Liechtenstein",
    p: "+423",
    c: "LI"
  },
  {
    n: "Lithuania",
    p: "+370",
    c: "LT"
  },
  {
    n: "Luxembourg",
    p: "+352",
    c: "LU"
  },
  {
    n: "Macao",
    p: "+853",
    c: "MO"
  },
  {
    n: "Macedonia",
    p: "+389",
    c: "MK"
  },
  {
    n: "Madagascar",
    p: "+261",
    c: "MG"
  },
  {
    n: "Malawi",
    p: "+265",
    c: "MW"
  },
  {
    n: "Malaysia",
    p: "+60",
    c: "MY"
  },
  {
    n: "Maldives",
    p: "+960",
    c: "MV"
  },
  {
    n: "Mali",
    p: "+223",
    c: "ML"
  },
  {
    n: "Malta",
    p: "+356",
    c: "MT"
  },
  {
    n: "Marshall Islands",
    p: "+692",
    c: "MH"
  },
  {
    n: "Martinique",
    p: "+596",
    c: "MQ"
  },
  {
    n: "Mauritania",
    p: "+222",
    c: "MR"
  },
  {
    n: "Mauritius",
    p: "+230",
    c: "MU"
  },
  {
    n: "Mayotte",
    p: "+262",
    c: "YT"
  },
  {
    n: "Mexico",
    p: "+52",
    c: "MX"
  },
  {
    n: "Micronesia, Federated States of Micronesia",
    p: "+691",
    c: "FM"
  },
  {
    n: "Moldova",
    p: "+373",
    c: "MD"
  },
  {
    n: "Monaco",
    p: "+377",
    c: "MC"
  },
  {
    n: "Mongolia",
    p: "+976",
    c: "MN"
  },
  {
    n: "Montenegro",
    p: "+382",
    c: "ME"
  },
  {
    n: "Montserrat",
    p: "+1664",
    c: "MS"
  },
  {
    n: "Morocco",
    p: "+212",
    c: "MA"
  },
  {
    n: "Mozambique",
    p: "+258",
    c: "MZ"
  },
  {
    n: "Myanmar",
    p: "+95",
    c: "MM"
  },
  {
    n: "Namibia",
    p: "+264",
    c: "NA"
  },
  {
    n: "Nauru",
    p: "+674",
    c: "NR"
  },
  {
    n: "Nepal",
    p: "+977",
    c: "NP"
  },
  {
    n: "Netherlands",
    p: "+31",
    c: "NL"
  },
  {
    n: "Netherlands Antilles",
    p: "+599",
    c: "AN"
  },
  {
    n: "New Caledonia",
    p: "+687",
    c: "NC"
  },
  {
    n: "New Zealand",
    p: "+64",
    c: "NZ"
  },
  {
    n: "Nicaragua",
    p: "+505",
    c: "NI"
  },
  {
    n: "Niger",
    p: "+227",
    c: "NE"
  },
  {
    n: "Nigeria",
    p: "+234",
    c: "NG"
  },
  {
    n: "Niue",
    p: "+683",
    c: "NU"
  },
  {
    n: "Norfolk Island",
    p: "+672",
    c: "NF"
  },
  {
    n: "Northern Mariana Islands",
    p: "+1670",
    c: "MP"
  },
  {
    n: "Norway",
    p: "+47",
    c: "NO"
  },
  {
    n: "Oman",
    p: "+968",
    c: "OM"
  },
  {
    n: "Pakistan",
    p: "+92",
    c: "PK"
  },
  {
    n: "Palau",
    p: "+680",
    c: "PW"
  },
  {
    n: "Palestinian Territory, Occupied",
    p: "+970",
    c: "PS"
  },
  {
    n: "Panama",
    p: "+507",
    c: "PA"
  },
  {
    n: "Papua New Guinea",
    p: "+675",
    c: "PG"
  },
  {
    n: "Paraguay",
    p: "+595",
    c: "PY"
  },
  {
    n: "Peru",
    p: "+51",
    c: "PE"
  },
  {
    n: "Philippines",
    p: "+63",
    c: "PH"
  },
  {
    n: "Pitcairn",
    p: "+872",
    c: "PN"
  },
  {
    n: "Poland",
    p: "+48",
    c: "PL"
  },
  {
    n: "Portugal",
    p: "+351",
    c: "PT"
  },
  {
    n: "Puerto Rico",
    p: "+1939",
    c: "PR"
  },
  {
    n: "Qatar",
    p: "+974",
    c: "QA"
  },
  {
    n: "Romania",
    p: "+40",
    c: "RO"
  },
  {
    n: "Russia",
    p: "+7",
    c: "RU"
  },
  {
    n: "Rwanda",
    p: "+250",
    c: "RW"
  },
  {
    n: "Reunion",
    p: "+262",
    c: "RE"
  },
  {
    n: "Saint Barthelemy",
    p: "+590",
    c: "BL"
  },
  {
    n: "Saint Helena, Ascension and Tristan Da Cunha",
    p: "+290",
    c: "SH"
  },
  {
    n: "Saint Kitts and Nevis",
    p: "+1869",
    c: "KN"
  },
  {
    n: "Saint Lucia",
    p: "+1758",
    c: "LC"
  },
  {
    n: "Saint Martin",
    p: "+590",
    c: "MF"
  },
  {
    n: "Saint Pierre and Miquelon",
    p: "+508",
    c: "PM"
  },
  {
    n: "Saint Vincent and the Grenadines",
    p: "+1784",
    c: "VC"
  },
  {
    n: "Samoa",
    p: "+685",
    c: "WS"
  },
  {
    n: "San Marino",
    p: "+378",
    c: "SM"
  },
  {
    n: "Sao Tome and Principe",
    p: "+239",
    c: "ST"
  },
  {
    n: "Saudi Arabia",
    p: "+966",
    c: "SA"
  },
  {
    n: "Senegal",
    p: "+221",
    c: "SN"
  },
  {
    n: "Serbia",
    p: "+381",
    c: "RS"
  },
  {
    n: "Seychelles",
    p: "+248",
    c: "SC"
  },
  {
    n: "Sierra Leone",
    p: "+232",
    c: "SL"
  },
  {
    n: "Singapore",
    p: "+65",
    c: "SG"
  },
  {
    n: "Slovakia",
    p: "+421",
    c: "SK"
  },
  {
    n: "Slovenia",
    p: "+386",
    c: "SI"
  },
  {
    n: "Solomon Islands",
    p: "+677",
    c: "SB"
  },
  {
    n: "Somalia",
    p: "+252",
    c: "SO"
  },
  {
    n: "South Africa",
    p: "+27",
    c: "ZA"
  },
  {
    n: "South Sudan",
    p: "+211",
    c: "SS"
  },
  {
    n: "South Georgia and the South Sandwich Islands",
    p: "+500",
    c: "GS"
  },
  {
    n: "Spain",
    p: "+34",
    c: "ES"
  },
  {
    n: "Sri Lanka",
    p: "+94",
    c: "LK"
  },
  {
    n: "Sudan",
    p: "+249",
    c: "SD"
  },
  {
    n: "Suriname",
    p: "+597",
    c: "SR"
  },
  {
    n: "Svalbard and Jan Mayen",
    p: "+47",
    c: "SJ"
  },
  {
    n: "Swaziland",
    p: "+268",
    c: "SZ"
  },
  {
    n: "Sweden",
    p: "+46",
    c: "SE"
  },
  {
    n: "Switzerland",
    p: "+41",
    c: "CH"
  },
  {
    n: "Syrian Arab Republic",
    p: "+963",
    c: "SY"
  },
  {
    n: "Taiwan",
    p: "+886",
    c: "TW"
  },
  {
    n: "Tajikistan",
    p: "+992",
    c: "TJ"
  },
  {
    n: "Tanzania, United Republic of Tanzania",
    p: "+255",
    c: "TZ"
  },
  {
    n: "Thailand",
    p: "+66",
    c: "TH"
  },
  {
    n: "Timor-Leste",
    p: "+670",
    c: "TL"
  },
  {
    n: "Togo",
    p: "+228",
    c: "TG"
  },
  {
    n: "Tokelau",
    p: "+690",
    c: "TK"
  },
  {
    n: "Tonga",
    p: "+676",
    c: "TO"
  },
  {
    n: "Trinidad and Tobago",
    p: "+1868",
    c: "TT"
  },
  {
    n: "Tunisia",
    p: "+216",
    c: "TN"
  },
  {
    n: "Turkey",
    p: "+90",
    c: "TR"
  },
  {
    n: "Turkmenistan",
    p: "+993",
    c: "TM"
  },
  {
    n: "Turks and Caicos Islands",
    p: "+1649",
    c: "TC"
  },
  {
    n: "Tuvalu",
    p: "+688",
    c: "TV"
  },
  {
    n: "Uganda",
    p: "+256",
    c: "UG"
  },
  {
    n: "Ukraine",
    p: "+380",
    c: "UA"
  },
  {
    n: "United Arab Emirates",
    p: "+971",
    c: "AE"
  },
  {
    n: "United Kingdom",
    p: "+44",
    c: "GB"
  },
  {
    n: "United States",
    p: "+1",
    c: "US"
  },
  {
    n: "Uruguay",
    p: "+598",
    c: "UY"
  },
  {
    n: "Uzbekistan",
    p: "+998",
    c: "UZ"
  },
  {
    n: "Vanuatu",
    p: "+678",
    c: "VU"
  },
  {
    n: "Venezuela, Bolivarian Republic of Venezuela",
    p: "+58",
    c: "VE"
  },
  {
    n: "Vietnam",
    p: "+84",
    c: "VN"
  },
  {
    n: "Virgin Islands, British",
    p: "+1284",
    c: "VG"
  },
  {
    n: "Virgin Islands, U.S.",
    p: "+1340",
    c: "VI"
  },
  {
    n: "Wallis and Futuna",
    p: "+681",
    c: "WF"
  },
  {
    n: "Yemen",
    p: "+967",
    c: "YE"
  },
  {
    n: "Zambia",
    p: "+260",
    c: "ZM"
  },
  {
    n: "Zimbabwe",
    p: "+263",
    c: "ZW"
  }
];

export default countryCodes;
