import React from "react";
import { LoadScript } from "@react-google-maps/api";
import { Grommet } from "grommet";
import { Route, Router, Switch } from "react-router-dom";
import styled from "styled-components";

import AppContextWrapper from "src/components/context/AppContext";
import HomePage from "src/components/HomePage";
import MapPage from "src/components/map/MapPage";
import PartnersPage from "src/components/partner/PartnersPage";
import AuthLayer from "src/components/user/AuthLayer";
import UsernameLayer from "src/components/user/UsernameLayer";
import UserProfilePage from "src/components/user/UserProfilePage";

import history from "src/lib/history";

const FlexGrommet = styled(Grommet)`
  height: auto;
  flex: 1;
  display: flex;
  overflow-x: hidden;
`;

const App: React.FC = () => (
  <LoadScript
    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY_MAPS}
    libraries={["places"]}
  >
    <AppContextWrapper>
      <FlexGrommet full>
        <AuthLayer />
        <UsernameLayer />
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/map/:mapId" component={MapPage} />
            <Route exact path="/map/:mapId/:tab" component={MapPage} />
            <Route exact path="/profile" component={UserProfilePage} />
            <Route exact path="/partners" component={PartnersPage} />
          </Switch>
        </Router>
      </FlexGrommet>
    </AppContextWrapper>
  </LoadScript>
);

export default App;
