import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Keyboard, MaskedInput, Text, TextInput } from "grommet";

export const Tag: React.FC<any> = ({ children, onRemove, ...rest }) => {
  const tag = (
    <Box
      direction="row"
      align="center"
      background="brand"
      pad={{ horizontal: "small", vertical: "xsmall" }}
      margin={{ vertical: "xxsmall" }}
      round="medium"
      {...rest}
    >
      <Text size="small" margin={{ right: onRemove && "xsmall" }}>
        {children}
      </Text>
      {onRemove && <FontAwesomeIcon size="sm" color="white" icon={faTimes} />}
    </Box>
  );

  if (onRemove) {
    return <Button onClick={onRemove}>{tag}</Button>;
  }
  return tag;
};

const TagInput: React.FC<any> = ({
  value = [],
  onAdd,
  onChange,
  onRemove,
  ...rest
}) => {
  const [currentTag, setCurrentTag] = React.useState("");
  const [box, setBox] = React.useState();
  const boxRef = React.useCallback(setBox, []);

  const updateCurrentTag = (event: any) => {
    const tag = event.target.value.replace(/\s/, "");
    setCurrentTag(tag);
    if (onChange) {
      onChange(event);
    }
  };

  const onAddTag = (tag: string) => {
    if (tag && onAdd) {
      onAdd(tag);
    }
  };

  const onTagKey = (e: React.KeyboardEvent<HTMLElement>) => {
    e.preventDefault();
    if (currentTag.length) {
      onAddTag(currentTag);
      setCurrentTag("");
    }
  };

  const renderValue = () =>
    value.map((v: any, index: any) => (
      <Tag
        margin="xxsmall"
        key={`${v}${index + 0}`}
        onRemove={() => onRemove(v)}
      >
        #{v}
      </Tag>
    ));

  return (
    <Keyboard onEnter={onTagKey} onComma={onTagKey} onSpace={onTagKey}>
      <Box
        direction="row"
        align="center"
        pad={{ horizontal: "xsmall" }}
        ref={boxRef}
        wrap
      >
        {value.length > 0 && renderValue()}
        <Box flex style={{ minWidth: "120px" }}>
          <MaskedInput
            plain
            dropTarget={box}
            mask={[
              {
                length: [1, 30],
                regexp: /^[a-zA-Z0-9]+$/
              }
            ]}
            {...rest}
            onChange={updateCurrentTag}
            value={currentTag}
          />
        </Box>
      </Box>
    </Keyboard>
  );
};

export default TagInput;
