import React from "react";
import { DataTable, DataTableProps } from "grommet";

import PartnerStatusButton from "src/components/partner/PartnerStatusButton";

const columns: DataTableProps["columns"] = [
  { header: "Name", property: "name" },
  { header: "Country", property: "country" },
  { header: "State", property: "state" },
  { header: "Postcode", property: "postcode" },
  { header: "Contact", property: "contactName" },
  {
    header: "Number",
    property: "contactNumber",
    render: ({ contactNumber, phone }) => contactNumber || phone
  },
  { header: "Suggestions", property: "suggestionCount", align: "center" },
  {
    header: "Status",
    property: "status",
    render: ({ id, status }: any) => (
      <PartnerStatusButton partnerId={id} status={status} />
    )
  }
];

interface PartnersTableProps {
  partners: any[];
}

const PartnersTable: React.FC<PartnersTableProps> = ({ partners }) => {
  return (
    <DataTable
      sortable
      columns={columns}
      data={partners}
      background={{
        header: "dark-2",
        body: ["light-1", "light-3"]
      }}
    />
  );
};

export default PartnersTable;
