import { migrateUserRefs, updateUserRefs } from "./api";
import { profileRef, userRef } from "./refs";

import { Error, FirebaseAuthErrorCode } from "src/lib/dartmap/types";
import { analytics, auth, database } from "src/lib/firebase";

export const requireAuth = async () => {
  if (!auth.currentUser) {
    await auth.signInAnonymously();
  }
  return auth.currentUser;
};

export const signOut = async () => {
  await auth.signOut();
  analytics.logEvent("signout");
};

export const finishAuth = async (credential: firebase.auth.AuthCredential) => {
  const user = auth.currentUser;
  if (user && user.isAnonymous) {
    await user.linkWithCredential(credential);
    analytics.logEvent("auth_linked", {
      method: credential.signInMethod,
      uid: user.uid
    });
  }
  if (!user) {
    await auth.signInWithCredential(credential);
  }
  analytics.logEvent("signin");
  analytics.setUserProperties({ [`signin_${credential.signInMethod}`]: true });
  return auth.currentUser;
};

export const handleLinkUserWithCredentialError = async (
  e: Error<FirebaseAuthErrorCode>,
  credential: firebase.auth.AuthCredential
) => {
  const { code } = e;
  analytics.logEvent("exception_handled", { code });
  switch (code) {
    default:
    case "auth/credential-already-in-use":
      break;
  }
  return auth.currentUser;
};

export const updateUsername = async (username: string) => {
  const user = auth.currentUser;
  if (user && username) {
    const lowerUsername = username.toLowerCase();
    await database.ref(`usernames/${lowerUsername}/inUse`).set(true);
    await database.ref(`usernames/${lowerUsername}/uid`).set(user.uid);
    await profileRef(user.uid).set(
      { isAnonymous: false, username },
      { merge: true }
    );
    await updateUserRefs();
    await user.updateProfile({
      displayName: username
    });
    analytics.logEvent("user_username_update", { uid: user.uid });
  }
};

export const isUsernameAvailable = async (username: string) => {
  const lowerUsername = username.toLowerCase();
  const inUseSnap = await database
    .ref(`usernames/${lowerUsername}/inUse`)
    .once("value");
  return inUseSnap.val() !== true;
};
