import React from "react";
import { Box } from "grommet";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

const FlexLinkComponent = styled(Link)`
  display: flex;
  flex: 1;
  text-decoration: none;
`;

const FlexLink: React.FC<LinkProps<any>> = ({ children, ...props }) => (
  <FlexLinkComponent {...props}>
    <Box flex>{children}</Box>
  </FlexLinkComponent>
);

export default FlexLink;
