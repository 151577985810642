import React, { ReactNode } from "react";
import { Box, Text } from "grommet";

interface Props {
  title: string;
  image?: string | ReactNode;
  subtitle: string;
  action?: ReactNode;
}

const EmptyState: React.FC<Props> = ({ action, image, title, subtitle }) => (
  <Box fill align="center" justify="center" gap="large">
    <Text size="xlarge">{title}</Text>
    {image ? image : null}
    <Text size="medium">{subtitle}</Text>
    {action}
  </Box>
);

export default EmptyState;
