import React, { useContext } from "react";
import { Box, Button, Layer } from "grommet";
import { AppContext } from "../context/AppContext";
import PhoneNumberAuth from "./PhoneNumberAuth";

import LayerHeader from "src/components/LayerHeader";

import { finishAuth, signOut } from "src/lib/dartmap";

const AuthLayer: React.FC = () => {
  const { user, showAuthLayer, setShowAuthLayer } = useContext(AppContext);

  if (!showAuthLayer) {
    return null;
  }

  const onHide = () => setShowAuthLayer(false);

  const onCredential = async (credential: firebase.auth.AuthCredential) => {
    await finishAuth(credential);
    onHide();
  };

  const onSignout = async () => {
    await signOut();
    onHide();
  };

  const credentialForm = (
    <PhoneNumberAuth onCredential={onCredential} onHide={onHide} />
  );

  return (
    <Layer onEsc={onHide} onClickOutside={onHide}>
      {!user || user.isAnonymous ? (
        <Box fill overflow="auto">
          <LayerHeader title="Sign In" onHide={onHide} />
          <Box pad="medium">
            Enter your details below to sign in or register a new account.
          </Box>
          <Box pad="medium">{credentialForm}</Box>
        </Box>
      ) : (
        <Box>
          <Button primary onClick={onSignout}></Button>
        </Box>
      )}
    </Layer>
  );
};

export default AuthLayer;
