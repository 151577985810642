import React, { useState } from "react";
import { Box, Button, Heading, Image } from "grommet";
import { Link } from "react-router-dom";
import Page from "./Page";

import NewMapLayer from "src/components/NewMapLayer";
import TopNav from "src/components/TopNav";

import HomeSvg from "src/assets/home.svg";
import LogoTextSvg from "src/assets/logoText.svg";

const HomePage: React.FC = () => {
  const [showNewMapLayer, setShowNewMapLayer] = useState<boolean>(false);
  return (
    <Page noHeader>
      <Box align="center" justify="center" pad="large" fill>
        {showNewMapLayer && (
          <NewMapLayer onHide={() => setShowNewMapLayer(false)} />
        )}
        <Image src={LogoTextSvg} width="50%" />
        <Image src={HomeSvg} />
        <Heading level={3} textAlign="center">
          Are you looking for travel suggestions?
        </Heading>
        <Button
          hoverIndicator={true}
          label="Get Started"
          color="dark-3"
          onClick={() => setShowNewMapLayer(true)}
        />
      </Box>
    </Page>
  );
};

export default HomePage;
