import { createBrowserHistory } from "history";
import { analytics } from "./firebase";

const history = createBrowserHistory();

history.listen(() => {
  analytics.logEvent("page_view", {
    page_location: window.location.href,
    page_title: document.title,
    page_path: window.location.pathname
  });
});

export default history;
