import React, { useContext, useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "firebase/app";
import {
  Box,
  Button,
  Form,
  FormField,
  MaskedInput,
  ResponsiveContext,
  Select,
  Text
} from "grommet";
import countryCodes from "src/data/countryCodes";
import styled from "styled-components";

import { setEventValue } from "src/lib/common";

interface Props {
  onCredential: (credential: firebase.auth.AuthCredential) => void;
  onHide: () => void;
}

const phoneCodes = Array.from(
  new Set(countryCodes.map(data => String(data.p).replace(/\s/, "")))
);

const NumberInput = styled(MaskedInput)`
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PhoneNumberAuth: React.FC<Props> = ({ onCredential }) => {
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [phoneOptions, setPhoneOptions] = useState<string[]>(phoneCodes);
  const [countryCode, setCountryCode] = useState<string>("");
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [applicationVerifier, setApplicationVerifier] = useState();
  const [verificationId, setVerificationId] = useState();

  const [sending, setSending] = useState<boolean>(false);
  const [verifying, setVerfiying] = useState<boolean>(false);

  const responsive = useContext(ResponsiveContext);

  useEffect(() => {
    setApplicationVerifier(
      new firebase.auth.RecaptchaVerifier("recaptcha-container")
    );
  }, []);

  const onSendCode = async () => {
    setSending(true);
    const provider = new firebase.auth.PhoneAuthProvider();
    try {
      const newVerificationId = await provider.verifyPhoneNumber(
        `${countryCode}${number}`,
        applicationVerifier
      );
      setVerificationId(newVerificationId);
      setSending(false);
      setCurrentTab(2);
    } catch (e) {
      console.error(e);
    }
  };

  const onVerifyCode = async () => {
    setVerfiying(true);
    if (verifyCode) {
      const credential = await firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verifyCode
      );
      await onCredential(credential);
    }
  };

  return (
    <Box>
      {currentTab === 1 && (
        <Form onSubmit={onSendCode}>
          <Box
            direction={responsive === "small" ? "column" : "row"}
            gap="medium"
          >
            <FormField label="Country Code">
              <Select
                options={phoneOptions}
                placeholder="+"
                value={countryCode}
                onClose={() => setPhoneOptions(phoneCodes)}
                onChange={({ option }) => setCountryCode(option)}
                onSearch={text => {
                  const escapedText = text.replace(/[^0-9]/g, "");
                  const exp = new RegExp(escapedText, "i");
                  setPhoneOptions(phoneCodes.filter(data => exp.test(data)));
                }}
              />
            </FormField>
            <Box flex>
              <FormField label="Phone number">
                <NumberInput
                  type="number"
                  mask={[{ regexp: /^[0-9 ]+$/ }]}
                  placeholder="Enter your mobile number"
                  onChange={setEventValue(setNumber)}
                  value={number}
                />
              </FormField>
            </Box>
          </Box>
          <Box pad={{ top: "small", bottom: "medium" }}>
            <Button
              type="submit"
              primary
              label={
                <Box pad="small" align="center">
                  {sending ? (
                    <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
                  ) : (
                    <Text>Submit</Text>
                  )}
                </Box>
              }
              disabled={sending || !number || !countryCode}
            />
          </Box>
          <Box id="recaptcha-container" align="center"></Box>
        </Form>
      )}
      {currentTab === 2 && (
        <Form onSubmit={onVerifyCode}>
          <Box>
            <FormField label="Verify Code">
              <NumberInput
                type="number"
                mask={[{ length: [1, 6], regexp: /[0-9 ]+/ }]}
                placeholder="Enter the code you just received"
                onChange={setEventValue(setVerifyCode)}
                value={verifyCode}
              />
            </FormField>
          </Box>
          <Box pad={{ top: "small", bottom: "medium" }}>
            <Button
              type="submit"
              primary
              label={
                <Box pad="small" align="center">
                  {verifying ? (
                    <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
                  ) : (
                    <Text>Submit</Text>
                  )}
                </Box>
              }
              disabled={verifying || !verifyCode}
            />
          </Box>
        </Form>
      )}
    </Box>
  );
};

export default PhoneNumberAuth;
