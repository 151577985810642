import React, { forwardRef } from "react";
import { GoogleMap, GoogleMapProps, Marker } from "@react-google-maps/api";

import Map from "src/models/Map";
import MapPlace from "src/models/MapPlace";

const mapOptions: google.maps.MapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  gestureHandling: "greedy",
  styles: [
    {
      featureType: "poi.business",
      elementType: "labels",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }
  ]
};

interface MapPageGoogleMapProps {
  map: Map;
  places?: MapPlace[];
  onMarkerClick?: (place: MapPlace) => void;
}

const MapPageGoogleMap: React.FC<MapPageGoogleMapProps &
  GoogleMapProps> = forwardRef(
  ({ map, places = [], onMarkerClick, ...props }, ref: any) => (
    <GoogleMap
      mapContainerStyle={{ flex: 1 }}
      ref={ref}
      options={mapOptions}
      {...props}
    >
      {places.map(place => (
        <Marker
          key={place.id}
          position={{ lat: place.lat, lng: place.lng }}
          onClick={() => {
            onMarkerClick && onMarkerClick(place);
          }}
        />
      ))}
    </GoogleMap>
  )
);

export default MapPageGoogleMap;
