import React, { useContext } from "react";
import { Box, ResponsiveContext } from "grommet";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

interface Props {
  url: string;
}

const MapShareButtons: React.FC<Props> = ({ url }) => {
  const responsive = useContext(ResponsiveContext);
  let size = 48;
  if (responsive === "medium") {
    size = 48;
  } else if (responsive === "large") {
    size = 56;
  }
  return (
    <Box direction="row" gap="small" justify="center">
      <FacebookShareButton url={url}>
        <FacebookIcon round={true} size={size} />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        url={url}
        appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
      >
        <FacebookMessengerIcon round={true} size={size} />
      </FacebookMessengerShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon round={true} size={size} />
      </TwitterShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon round={true} size={size} />
      </WhatsappShareButton>
    </Box>
  );
};

export default MapShareButtons;
