export const getPlacesAutocomplete = (
  input: string,
  options?: google.maps.places.AutocompletionRequest
): Promise<google.maps.places.AutocompletePrediction[]> => {
  return new Promise((resolve, reject) => {
    const autocomplete = new google.maps.places.AutocompleteService();

    try {
      autocomplete.getPlacePredictions({ ...options, input }, result => {
        resolve(result);
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const getPlace = (
  placeId: string
): Promise<google.maps.places.PlaceResult> => {
  return new Promise((resolve, reject) => {
    const places = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    try {
      places.getDetails({ placeId }, (result, status) => {
        resolve(result);
      });
    } catch (e) {
      reject(e);
    }
  });
};
