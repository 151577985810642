import axios, { AxiosRequestConfig } from "axios";

import { auth } from "src/lib/firebase";

const API_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

export const updateUserRefs = async () => {
  const user = auth.currentUser;
  if (!user || user.isAnonymous) {
    return;
  }
  const token = await user.getIdToken();
  const request: AxiosRequestConfig = {
    method: "POST",
    url: `${API_BASE_URL}/httpsUpdateUserRefs`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  return await axios(request);
};

export const migrateUserRefs = async (token: string, newUid: string) => {
  const request: AxiosRequestConfig = {
    method: "POST",
    url: `${API_BASE_URL}/httpsMigrateUserRefs`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      newUid
    }
  };
  return await axios(request);
};
