import React, { ChangeEvent, ReactNode, useContext, useState } from "react";
import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Form, FormField, Layer, MaskedInput, Text, TextInput } from "grommet";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useDebouncedCallback } from "use-debounce/lib";

import { AppContext } from "src/components/context/AppContext";
import LayerHeader from "src/components/LayerHeader";

import { Profile } from "src/models/Profile";

import { setEventValue } from "src/lib/common";
import { isUsernameAvailable, updateUsername } from "src/lib/dartmap";
import { profileRef } from "src/lib/dartmap/refs";

const UsernameLayer: React.FC = () => {
  const { user, showUsernameLayer, setShowUsernameLayer } = useContext(
    AppContext
  );
  const uid = user ? user.uid : " ";
  const [profile, profileLoading] = useDocumentData<Profile>(profileRef(uid));
  const [username, setUsername] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const [usernameSet, setUsernameSet] = useState<boolean>(false);
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(false);
  const [message, setMessage] = useState<ReactNode>();

  const [checkUsernameAvailable] = useDebouncedCallback(
    async (username: string) => {
      setMessage(
        <Text color="light-5">
          <FontAwesomeIcon icon={faSpinner} spin /> Checking username
          avilability
        </Text>
      );
      const available = await isUsernameAvailable(username);
      if (available) {
        setMessage(
          <Text color="status-ok">
            <FontAwesomeIcon icon={faCheck} /> Username is available
          </Text>
        );
      } else {
        setMessage(
          <Text color="status-error">
            <FontAwesomeIcon icon={faTimes} /> Username is not available
          </Text>
        );
      }
      setUsernameAvailable(available);
    },
    500,
    { trailing: true }
  );

  if (user && !user.isAnonymous && !user.displayName && !showUsernameLayer) {
    setShowUsernameLayer(true);
  }

  if (!usernameSet && profile && profile.username) {
    let newUsername = profile.username === "Anonymous" ? "" : profile.username;
    setUsername(newUsername);
    setUsernameSet(true);
  }

  const onSave = async () => {
    if (username) {
      setSaving(true);
      await updateUsername(username);
      setShowUsernameLayer(false);
    }
  };

  const onUsernameChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setUsername(value);
    if (value.length < 1) {
      setMessage(
        <Text color="status-error">
          <FontAwesomeIcon icon={faTimes} /> Username must be 1-30 alpha numeric
          characters, including underscore and fullstop.
        </Text>
      );
    } else {
      checkUsernameAvailable(value);
    }
  };

  return showUsernameLayer ? (
    <Layer margin="large">
      <Box fill overflow="auto">
        <LayerHeader title="Set a username" />
        <Box pad="medium">
          <Form onSubmit={onSave}>
            <FormField label="Username">
              <MaskedInput
                mask={[{ length: [1, 30], regexp: /^[a-zA-Z0-9\.\_]+$/ }]}
                placeholder="You need to pick a username to continue"
                value={username}
                disabled={profileLoading}
                onChange={onUsernameChange}
              />
            </FormField>
            {message && <Box pad="small">{message}</Box>}
            <Box>
              <Button
                type="submit"
                primary
                label={
                  <Box pad="small" align="center">
                    {saving ? (
                      <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
                    ) : (
                      <Text>Submit</Text>
                    )}
                  </Box>
                }
                disabled={saving || !username || !usernameAvailable}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </Layer>
  ) : null;
};

export default UsernameLayer;
