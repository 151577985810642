export type LayerId = "DeletePlace";

export type LayerMap = {
  [K in LayerId]: {
    visible: boolean;
    data?: any;
  };
};

export const defaultLayerMap = {
  DeletePlace: {
    visible: false,
    data: { id: null }
  }
};
