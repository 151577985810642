import React, { useContext, useLayoutEffect, useState } from "react";
import { faMapMarkedAlt, faSpinner, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Heading, Layer, ResponsiveContext, Text } from "grommet";
import { useCollectionData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import DropConfirmButton from "../ConfirmLayer";
import { AppContext } from "../context/AppContext";
import EmptyState from "../EmptyState";
import LayerHeader from "../LayerHeader";
import LoadingBox from "../LoadingBox";
import MapPlaceReviewItem from "./MapPlaceReviewItem";

import Map from "src/models/Map";
import MapPlaceReview from "src/models/MapPlaceReview";

import { deleteMapPlace } from "src/lib/dartmap";
import { mapPlaceReviewsRef } from "src/lib/dartmap/refs";

const MapPlace = styled(Box)`
  height: 40%;
`;

interface Props {
  map: Map;
  place: any;
  onHide: () => void;
}

const MapPlaceLayer: React.FC<Props> = ({ map, place, onHide }) => {
  const reviewsQuery = mapPlaceReviewsRef(map.id, place.id);
  const [reviews] = useCollectionData<MapPlaceReview>(reviewsQuery);
  const responsive = useContext(ResponsiveContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [deletingPlace, setDeletingPlace] = useState<boolean>(false);
  const { user } = useContext(AppContext);

  const isMapOwner = user && user.uid && map && map.uid === user.uid;

  useLayoutEffect(() => {
    setTimeout(() => {
      const mapElement = document.getElementById("map-place");
      const { lat, lng } = place;
      const position = new google.maps.LatLng(lat, lng);
      if (mapElement && lat && lng) {
        const googleMap = new google.maps.Map(mapElement, {
          center: position,
          zoom: 15,
          fullscreenControl: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          gestureHandling: "greedy",
          styles: [
            {
              featureType: "poi.business",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            }
          ]
        });

        googleMap.addListener("dragend", () => {
          googleMap.panTo(position);
        });

        new google.maps.Marker({
          position,
          map: googleMap,
          title: place.name,
          place: place.placeId
        });
      }
    }, 200);
  }, [place]);

  let reviewsComponent = <LoadingBox text="Loading reviews" />;

  if (reviews && reviews.length > 0) {
    reviewsComponent = (
      <Box>
        {reviews.map(review => (
          <MapPlaceReviewItem key={review.id} review={review} />
        ))}
      </Box>
    );
  } else if (reviews && reviews.length < 1) {
    reviewsComponent = (
      <EmptyState
        title="No reviews"
        subtitle="No reviews have been left for this place"
      />
    );
  }

  const confirmDeletePlace = async () => {
    setShowConfirmDelete(false);
    setDeletingPlace(true);
    await deleteMapPlace(map.id, place.id);
    onHide();
  };

  const renderDeleteButton = () => (
    <DropConfirmButton
      color="status-critical"
      open={showConfirmDelete}
      disabled={deletingPlace}
      dropAlign={{ top: "bottom", right: "right" }}
      onOpen={() => setShowConfirmDelete(true)}
      onClose={() => setShowConfirmDelete(false)}
      onConfirm={confirmDeletePlace}
    >
      {deletingPlace ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <FontAwesomeIcon icon={faTrashAlt} />
      )}
    </DropConfirmButton>
  );

  return (
    <Layer full>
      <Box fill overflow="auto">
        <LayerHeader full title={place.name} onHide={onHide}></LayerHeader>
        <Box pad={{ top: "medium" }}></Box>
        <MapPlace id="map-place" />
        <Box pad="medium" fill>
          <Box direction="row" justify="between">
            <Text size={responsive}>
              <FontAwesomeIcon icon={faMapMarkedAlt} /> {place.address}
            </Text>
            {isMapOwner && renderDeleteButton()}
          </Box>
          <Heading level={3}>Reviews</Heading>
          {reviewsComponent}
        </Box>
      </Box>
    </Layer>
  );
};

export default MapPlaceLayer;
