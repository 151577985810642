import { ChangeEvent } from "react";

export const setEventValue = (callback: (value: any) => void) => (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  try {
    const {
      target: { value }
    } = event;
    callback(value);
  } catch (e) {
    console.error(e);
  }
};
