import React, { useContext, useState } from "react";
import { Box, Button, Form, FormField, Text, TextArea } from "grommet";
import { useDebouncedCallback } from "use-debounce/lib";
import { AppContext } from "../context/AppContext";
import TagInput from "../TagInput";
import MapShareButtons from "./MapShareButtons";

import Map from "src/models/Map";

import { saveMap } from "src/lib/dartmap";
import { analytics } from "src/lib/firebase";

interface Props {
  map: Map;
}

const TAG_LIMIT = 10;

const MapDetailsForm: React.FC<Props> = ({ map }) => {
  const [tagsDirty, setTagsDirty] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [desc, setDesc] = useState<string>(map.desc);
  const { setShowAuthLayer } = useContext(AppContext);

  const [saveMapData] = useDebouncedCallback(
    (mapData: Partial<Map>) => saveMap(map.id, mapData),
    2000,
    { leading: true }
  );

  const onChangeDesc = (desc: string) => {
    setDesc(desc);
    saveMapData({ desc });
    analytics.logEvent("map_desc_update");
  };

  const onRemoveTag = (tag: string) => {
    const newTags = tagsDirty ? [...selectedTags] : [...map.tags];
    const removeIndex = newTags.indexOf(tag);
    if (removeIndex >= 0) {
      newTags.splice(removeIndex, 1);
    }
    setSelectedTags(newTags);
    saveMapData({ tags: newTags });
    setTagsDirty(true);
    analytics.logEvent("map_tag_add", { tag });
  };

  const onAddTag = (tag: string) => {
    const newTags = tagsDirty ? [...selectedTags] : [...map.tags];
    if (selectedTags.length < TAG_LIMIT && newTags.indexOf(tag) < 0) {
      newTags.push(tag);
      setSelectedTags(newTags);
      saveMapData({ tags: newTags });
      setTagsDirty(true);
      analytics.logEvent("map_tag_add", { tag });
    }
  };

  const mapUrl = `${window.location.origin}/map/${map.id}`;

  return (
    <Form>
      {map.isAnonymous && (
        <Box background="light-3" pad={{ vertical: "large" }} align="center">
          <Text margin={{ bottom: "medium" }}>
            <strong>Eeeek!</strong>
          </Text>
          <Text margin={{ bottom: "medium" }} textAlign="center">
            This map is not linked with an account
          </Text>
          <Button
            color="brand"
            primary
            label="Save my map"
            onClick={() => setShowAuthLayer(true)}
          />
        </Box>
      )}
      <Box pad={{ top: "medium" }}>
        <Box pad={{ bottom: "medium" }}>
          <FormField label="Description">
            <TextArea
              resize={false}
              rows={3}
              placeholder="Looking for the best Nacho's in town!"
              value={desc}
              onChange={({ target: { value: desc } }) => {
                onChangeDesc(desc);
              }}
            />
          </FormField>
        </Box>
        {/* <Box direction="row" pad={{ bottom: "medium" }}>
          <Box flex>
            <FormField label="Arriving" placeholder="Feb 30, 2020" />
          </Box>
          <Box flex margin={{ left: "medium" }}>
            <FormField label="Leaving" placeholder="Never!!" />
          </Box>
        </Box> */}
        <Box pad={{ bottom: "medium" }}>
          <FormField label="Tags (10 max)">
            <TagInput
              value={tagsDirty ? selectedTags : map.tags}
              placeholder={selectedTags.length < TAG_LIMIT ? "#vacay" : ""}
              onRemove={onRemoveTag}
              onAdd={onAddTag}
            />
          </FormField>
        </Box>
        <Box pad={{ vertical: "large" }} justify="center">
          <MapShareButtons url={mapUrl} />
        </Box>
      </Box>
    </Form>
  );
};

export default MapDetailsForm;
