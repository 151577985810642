import React, { useContext } from "react";
import { Box } from "grommet";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { AppContext } from "../context/AppContext";
import LoadingBox from "../LoadingBox";

import Page from "src/components/Page";
import PartnersTable from "src/components/partner/PartnersTable";

import { partnersRef } from "src/lib/dartmap/refs";

const PartnersPage: React.FC = () => {
  const { user } = useContext(AppContext);
  const [partners, partnersLoading] = useCollectionData(partnersRef());
  return (
    <Page title="Partners List">
      {partnersLoading || !partners || !user ? (
        <LoadingBox text="Loading Partners" />
      ) : (
        <Box pad="medium">
          <PartnersTable partners={partners} />
        </Box>
      )}
    </Page>
  );
};

export default PartnersPage;
