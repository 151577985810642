import React, { useContext, useEffect, useState } from "react";
import { faBars, faCheck, faHome, faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Heading, ResponsiveContext, TextInput } from "grommet";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import MapPageGoogleMap from "./MapPageGoogleMap";

import AddSuggestionLayer from "src/components/AddSuggestionLayer";
import { AppContext } from "src/components/context/AppContext";
import EmptyState from "src/components/EmptyState";
import LoadingBox from "src/components/LoadingBox";
import MapDetails from "src/components/map/MapDetails";
import MapDetailsForm from "src/components/map/MapDetailsForm";
import MapPageTabButtons from "src/components/map/MapPageTabButtons";
import MapPlaceItem from "src/components/map/MapPlaceItem";
import MapPlaceLayer from "src/components/map/MapPlaceLayer";
import Page from "src/components/Page";

import Map from "src/models/Map";

import { setEventValue } from "src/lib/common";
import { saveMap } from "src/lib/dartmap";
import { mapPlacesRef, mapRef } from "src/lib/dartmap/refs";
import { analytics } from "src/lib/firebase";

const TabBox = styled(Box)`
  display: ${props => (props.hidden ? "none" : "flex")};
  max-width: 1200px;
  margin: 0 auto;
`;

const FloatingMapPlace = styled(Box)<{ show: boolean }>`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 9;
  opacity: ${props => (props.show ? 100 : 0)};
  transition: 100ms linear;
`;

const WhiteTextInput = styled(TextInput)`
  background: white;
`;

type PageTab = "details" | "places" | "map";

const MapPage: React.FC = () => {
  const { mapId = "", tab } = useParams();

  const currentTab: PageTab = (tab as PageTab) || "details";
  const [currentPlace, setCurrentPlace] = useState<any>();
  const [editingTitle, setEditingTitle] = useState<boolean>();
  const [newMapTitle, setNewMapTitle] = useState<string>("");
  const [boundsFit, setBoundsFit] = useState<boolean>(false);
  const responsive = useContext(ResponsiveContext);

  const [map, mapLoading] = useDocumentData<Map>(mapRef(mapId));
  const [places, placesLoading] = useCollectionData<any>(mapPlacesRef(mapId));
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();

  const [showSuggestionLayer, setShowSuggestionLayer] = useState<boolean>(
    false
  );
  const [showPlaceLayer, setShowPlaceLayer] = useState<any>();

  const { user, userLoading } = useContext(AppContext);

  useEffect(() => {
    fitBounds();
  }, [places, googleMap]);

  const fitBounds = () => {
    if (
      currentTab === "map" &&
      googleMap &&
      places &&
      places.length > 0 &&
      !boundsFit
    ) {
      const bounds = new google.maps.LatLngBounds();
      places.map(place => {
        const { lat, lng } = place;
        const position = new google.maps.LatLng(lat, lng);
        bounds.extend(position);
      });
      googleMap.fitBounds(bounds);
      setBoundsFit(true);
    }
  };

  const toggleEditingTitle = () => {
    if (editingTitle && newMapTitle) {
      saveMap(mapId, { title: newMapTitle });
      analytics.logEvent("map_title_update", { map_id: mapId });
    }
    if (!newMapTitle && map && map.title) {
      setNewMapTitle(map.title);
    }
    setEditingTitle(!editingTitle);
  };

  const userLoaded = !userLoading;
  const isAdmin = !userLoading && user && map && user.uid === map.uid;

  if (mapLoading) {
    return <LoadingBox text="Loading map" />;
  }

  const topNavRight =
    !userLoading && isAdmin && false ? (
      <Button hoverIndicator icon={<FontAwesomeIcon icon={faBars} />} />
    ) : (
      <Button
        hoverIndicator
        onClick={() => setShowSuggestionLayer(true)}
        icon={<FontAwesomeIcon icon={faPlus} />}
      />
    );

  if (!map) {
    return (
      <Page title="Map not found" noHeader>
        <EmptyState
          title="Uh oh!"
          subtitle="We cant find that map"
          action={
            <Link to="/">
              <Button
                label="Return home"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
            </Link>
          }
        />
      </Page>
    );
  }

  const mapTitle = newMapTitle || (map && map.title) || "My new map";

  return (
    <Page
      title={mapTitle}
      topNavRight={topNavRight}
      header={
        <Box
          flex
          pad={{ vertical: "small", horizontal: "medium" }}
          direction="row"
          gap="small"
        >
          {editingTitle ? (
            <WhiteTextInput
              size={responsive}
              value={newMapTitle}
              onChange={setEventValue(setNewMapTitle)}
              placeholder="Enter a title"
            />
          ) : (
            <Heading
              level={2}
              margin="0"
              truncate
              style={{ padding: "2px 0px" }}
            >
              {mapTitle}
            </Heading>
          )}
          {isAdmin && (
            <Button
              margin={{ left: "small" }}
              hoverIndicator
              focusIndicator={false}
              onClick={toggleEditingTitle}
              icon={
                <FontAwesomeIcon
                  color={editingTitle ? "#00C781" : undefined}
                  icon={editingTitle ? faCheck : faPencilAlt}
                />
              }
            />
          )}
        </Box>
      }
    >
      {showSuggestionLayer && (
        <AddSuggestionLayer
          map={map}
          onHide={() => setShowSuggestionLayer(false)}
        />
      )}
      {showPlaceLayer && (
        <MapPlaceLayer
          map={map}
          place={showPlaceLayer}
          onHide={() => {
            setShowPlaceLayer(null);
            setCurrentPlace(null);
          }}
        />
      )}

      <MapPageTabButtons map={map} currentTab={currentTab} />
      <TabBox
        pad={{ vertical: "xsmall" }}
        fill
        hidden={currentTab !== "details"}
      >
        <Box pad={{ horizontal: "large", vertical: "medium" }}>
          {userLoaded ? (
            isAdmin ? (
              <MapDetailsForm map={map} />
            ) : (
              <MapDetails
                map={map}
                setShowSuggestionLayer={setShowSuggestionLayer}
              />
            )
          ) : null}
        </Box>
      </TabBox>
      <TabBox
        pad={{ horizontal: "large", bottom: "large" }}
        fill
        hidden={currentTab !== "places"}
      >
        {placesLoading && <LoadingBox text="Loading places" />}
        {!placesLoading && places && places.length < 1 && (
          <EmptyState
            title="No places added yet"
            subtitle="Be the first to add a place to go"
            action={
              <Button
                hoverIndicator
                icon={<FontAwesomeIcon icon={faPlus} />}
                label="Add a place"
                onClick={() => setShowSuggestionLayer(true)}
              />
            }
          />
        )}
        {!placesLoading && places && places.length > 0 && (
          <Box>
            {places.map(place => (
              <MapPlaceItem
                key={place.address}
                place={place}
                onClick={() => setShowPlaceLayer(place)}
              />
            ))}
            <Box margin={{ bottom: "large" }}>
              <Button
                label="Add a place"
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => setShowSuggestionLayer(true)}
              />
              <Box height="20px">&nbsp;</Box>
            </Box>
          </Box>
        )}
      </TabBox>
      <TabBox
        fill
        pad={{ vertical: "xsmall" }}
        hidden={currentTab !== "map"}
        style={{ maxWidth: "initial" }}
      >
        <Box flex>
          <MapPageGoogleMap
            id="map"
            map={map}
            places={places}
            onLoad={setGoogleMap}
            onMarkerClick={setCurrentPlace}
            onClick={e => {
              e.stop();
              setCurrentPlace(null);
            }}
          />
        </Box>
        <FloatingMapPlace pad={"large"} show={currentPlace}>
          {currentPlace && (
            <MapPlaceItem
              place={currentPlace}
              onClick={() => setShowPlaceLayer(currentPlace)}
            />
          )}
        </FloatingMapPlace>
      </TabBox>
    </Page>
  );
};

export default MapPage;
