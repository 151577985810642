import React from "react";
import { Box, BoxTypes } from "grommet";

const Separator: React.FC<BoxTypes> = props => (
  <Box
    border={{ color: "light-5", size: "2px", side: "bottom" }}
    {...props}
  ></Box>
);

export default Separator;
