import React, { useContext, useState } from "react";
import { faCross, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Form,
  FormField,
  Header,
  Heading,
  Image,
  Layer,
  Main,
  RangeInput,
  ResponsiveContext,
  Text,
  TextArea
} from "grommet";
import styled from "styled-components";
import LayerHeader from "./LayerHeader";
import MapPlaceItem from "./map/MapPlaceItem";
import RatingFace from "./RatingFace";

import PlaceSuggestionField from "src/components/PlaceSuggesionField";

import Map from "src/models/Map";
import MapPlace from "src/models/MapPlace";

import { setEventValue } from "src/lib/common";
import { saveMapPlaceReview, saveNewSuggestion } from "src/lib/dartmap";
import { getPlace } from "src/lib/places";

interface Props {
  onHide: () => void;
  map: Map;
}

const AddSuggestionLayer: React.FC<Props> = ({ onHide, map }) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [selectedPlace, setSelectedPlace] = useState<
    google.maps.places.AutocompletePrediction
  >();
  const [newPlace, setNewPlace] = useState<MapPlace>();
  const [comment, setComment] = useState<string>("");
  const [rating, setRating] = useState<number>(4);

  const saveSuggestion = async () => {
    if (saving || !selectedPlace || !selectedPlace.place_id) {
      return;
    }
    setSaving(true);
    const place = await getPlace(selectedPlace.place_id);
    const mapPlaceRef = await saveNewSuggestion(map.id, place);
    const mapPlacedoc = await mapPlaceRef.get();
    setNewPlace(mapPlacedoc.data() as MapPlace);
    setSaving(false);
    setCurrentTab(2);
  };

  const saveReview = async () => {
    if (saving || !selectedPlace || !selectedPlace.place_id) {
      return;
    }
    setSaving(true);
    await saveMapPlaceReview(map.id, selectedPlace.place_id, {
      comment,
      rating
    });
    onHide();
  };

  let options: google.maps.places.AutocompletionRequest;
  if (map) {
    const location = new google.maps.LatLng(map.lat, map.lng);
    options = {
      location,
      radius: 17,
      input: ""
    };
  }

  const SubmitButton = () => (
    <Box pad={{ top: "small", bottom: "medium" }}>
      <Button
        type="submit"
        primary
        label={
          <Box pad="small" align="center">
            {saving ? (
              <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
            ) : (
              <Text>Submit</Text>
            )}
          </Box>
        }
        disabled={!selectedPlace || saving}
      />
    </Box>
  );

  const renderPage1 = () => (
    <Form onSubmit={saveSuggestion}>
      <PlaceSuggestionField
        options={options}
        onSelect={setSelectedPlace}
        saving={saving}
      />
      <SubmitButton />
    </Form>
  );

  const renderPage2 = () => (
    <Form onSubmit={saveReview}>
      {newPlace && (
        <Box pad={{ bottom: "large" }}>
          <Text size="large">{newPlace.name}</Text>
          <Text size="small" color="dark-3">
            {newPlace.address}
          </Text>
        </Box>
      )}
      <Text>Rating</Text>
      <Box justify="around" direction="row" pad={{ vertical: "large" }}>
        {[1, 2, 3, 4, 5].map(i => (
          <RatingFace
            key={`ratingFace${i}`}
            value={i}
            selected={rating === i}
            onClick={setRating}
            style={{ maxWidth: 56 }}
          />
        ))}
      </Box>
      <FormField label="Comment">
        <TextArea
          value={comment}
          resize={false}
          rows={5}
          onChange={setEventValue(setComment)}
        />
      </FormField>
      <SubmitButton />
      <Box pad={{ top: "small", bottom: "medium" }}>
        <Button
          type="submit"
          label="No review"
          disabled={!selectedPlace || saving}
          onClick={() => onHide()}
        />
      </Box>
    </Form>
  );

  const layerTitle = currentTab === 1 ? "Add a place" : "Review place";

  return (
    <Layer onEsc={onHide} onClickOutside={onHide}>
      <Box fill overflow="auto">
        <LayerHeader title={layerTitle} onHide={onHide} />
        <Box pad="medium" fill>
          {currentTab === 1 && renderPage1()}
          {currentTab === 2 && renderPage2()}
        </Box>
      </Box>
    </Layer>
  );
};

export default AddSuggestionLayer;
