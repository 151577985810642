import React from "react";
import { faPlus, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { Box, Button, Text } from "grommet";
import Separator from "../Separator";
import { Tag } from "../TagInput";

import Map from "src/models/Map";

interface Props {
  map: Map;
  setShowSuggestionLayer: (show: boolean) => void;
}

const MapDetails: React.FC<Props> = ({ map, setShowSuggestionLayer }) => {
  let mapDescSize = "small";

  if (String(map.desc).length < 40) {
    mapDescSize = "large";
  } else if (String(map.desc).length < 80) {
    mapDescSize = "medium";
  }

  return (
    <Box>
      <Box pad={{ bottom: "large" }}>
        <Box direction="row" gap="small" align="center">
          <Box width="40px" align="center">
            <FontAwesomeIcon icon={faUserCircle} size="2x" />
          </Box>
          <Box>
            <Text>{map.isAnonymous ? "Anonymous" : map.username}</Text>
            <Text color="dark-3" size="small">
              {format(map.created.seconds * 1000, "hh:mm a - dd/MM/yyyy")}
            </Text>
          </Box>
        </Box>
        <Box margin={{ horizontal: "small", vertical: "large" }}>
          {map.desc ? (
            <Text size={mapDescSize}>{map.desc}</Text>
          ) : (
            <Text color="dark-5">No description</Text>
          )}
        </Box>
        <Box direction="row" gap="small" margin={{ horizontal: "small" }}>
          {map.tags && map.tags.length > 0 ? (
            map.tags.map(tag => <Tag key={tag}>#{tag}</Tag>)
          ) : (
            <Text color="dark-5">No Tags</Text>
          )}
        </Box>
      </Box>
      <Separator />
      <Box direction="row" pad="large" justify="center">
        <Button
          hoverIndicator
          icon={<FontAwesomeIcon icon={faPlus} />}
          label="Add a place"
          onClick={() => setShowSuggestionLayer(true)}
        />
      </Box>
    </Box>
  );
};

export default MapDetails;
