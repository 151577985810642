import React from "react";
import { Box, Button, ButtonType, Text } from "grommet";
import RatingFace from "../RatingFace";

interface Props extends ButtonType {
  place: any;
}

// const PlaceImage = styled(Image)`
//   height: 56px;
//   width: 56px;
//   border-radius: 28px;
// `;

const MapPlaceItem: React.FC<Props> = ({ place, ...props }) => {
  const roundedRating = Math.round(place.rating / place.ratings);
  return (
    <Button
      margin={{ bottom: "small" }}
      key={place.name}
      color="light-5"
      style={{ background: "white" }}
      label={
        <Box
          justify="between"
          direction="row"
          pad={{ vertical: "xsmall" }}
          gap="small"
        >
          <Box direction="row" align="center">
            {/* <div style={{ width: 56 }}>
            <PlaceImage src={place.img} fit="cover" />
          </div> */}
            <Box align="start">
              <Box>
                <Text textAlign="start" truncate>
                  {place.name}
                </Text>
              </Box>
              <Box>
                <Text size="xsmall" textAlign="start" truncate>
                  {place.address}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box align="center" justify="center">
            <RatingFace
              value={roundedRating > 0 ? roundedRating : 4}
              style={{ height: 32 }}
            />
          </Box>
        </Box>
      }
      {...props}
    />
  );
};

export default MapPlaceItem;
