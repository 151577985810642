import React from "react";
import { Box, Button, ButtonProps, DropButton, DropButtonProps, Heading, Text } from "grommet";

interface DropConfirmButtonProps extends Omit<DropButtonProps, "dropContent"> {
  onClose: (...args: any[]) => any;
  onConfirm: (...args: any[]) => any;
  confirmText?: string;
  cancelText?: string;
  title?: string;
  text?: string;
}

const DropConfirmButton: React.FC<DropConfirmButtonProps & ButtonProps> = ({
  onClose,
  onConfirm,
  confirmText = "Confirm",
  cancelText = "Cancel",
  title = "Confirm",
  text = "Are you sure?",
  ...props
}) => (
  <DropButton
    dropContent={
      <Box pad="medium" gap="small" width="medium">
        <Heading level={3} margin="none">
          {title}
        </Heading>
        <Text>{text}</Text>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: "medium", bottom: "small" }}
        >
          <Button label={cancelText} onClick={onClose} color="dark-3" />
          <Button
            label={<Text color="white">{confirmText}</Text>}
            onClick={onConfirm}
            primary
            color="status-critical"
          />
        </Box>
      </Box>
    }
    onClose={onClose}
    {...props}
  />
);

export default DropConfirmButton;
