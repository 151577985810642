import Map from "src/models/Map";
import MapPlaceReview from "src/models/MapPlaceReview";

import {
  mapPlaceRef,
  mapPlaceReviewRef,
  mapsRef,
  placeRef,
  serverTimestamp,
  suggestionRef,
  suggestionRefId
} from "src/lib/dartmap/refs";
import { requireAuth } from "src/lib/dartmap/user";
import { analytics, firestore } from "src/lib/firebase";

export const saveNewMap = async (map: Partial<Map>) => {
  const user = await requireAuth();
  if (!user) {
    return;
  }
  const newMapRef = mapsRef().doc();
  await newMapRef.set({
    ...map,
    id: newMapRef.id,
    isAnonymous: user.isAnonymous,
    username: user.displayName || "Anonymous",
    uid: user.uid,
    title: "My new map",
    created: serverTimestamp(),
    updated: serverTimestamp()
  });
  const { lat, lng } = map;
  analytics.logEvent("map_add", {
    map_id: map.id,
    lat,
    lng
  });
  return newMapRef;
};

export const saveNewSuggestion = async (
  mapId: string,
  place: google.maps.places.PlaceResult
) => {
  const user = await requireAuth();
  if (!user) {
    throw new Error("User Required");
  }
  if (!place.place_id) {
    throw new Error("Place Required");
  }
  const { lat = 0, lng = 0 } = place.geometry
    ? place.geometry.location.toJSON()
    : {};
  const placeData = JSON.parse(JSON.stringify(place));
  const placePromise = placeRef(place.place_id).set(placeData);
  const uid = user.uid;
  const newMapPlaceRef = mapPlaceRef(mapId, place.place_id);
  const mapPlace = await newMapPlaceRef.get();
  let mapPlacePromise = Promise.resolve();
  if (!mapPlace.exists) {
    mapPlacePromise = newMapPlaceRef.set({
      id: place.place_id,
      name: place.name,
      address: place.formatted_address,
      lat,
      lng,
      uid,
      isAnonymous: true,
      created: serverTimestamp(),
      updated: serverTimestamp()
    });
  }
  const suggestionId = suggestionRefId(mapId, uid, place.place_id);
  analytics.logEvent("map_place_add", {
    map_id: mapId,
    place_Id: place.place_id
  });
  const suggestionPromise = suggestionRef(suggestionId).set({
    id: suggestionId,
    mapId,
    placeId: place.place_id,
    name: place.name,
    address: place.formatted_address,
    lat,
    lng,
    uid,
    isAnonymous: true,
    created: serverTimestamp(),
    updated: serverTimestamp()
  });
  await Promise.all([placePromise, mapPlacePromise, suggestionPromise]);
  return newMapPlaceRef;
};

export const saveMapPlaceReview = async (
  mapId: string,
  placeId: string,
  mapPlaceReview: Partial<MapPlaceReview>
) => {
  const user = await requireAuth();
  if (!user) {
    return;
  }
  const uid = user.uid;
  analytics.logEvent("map_place_review_update", {
    map_id: mapId,
    place_id: placeId,
    rating: mapPlaceReview.rating || 0
  });
  const newMapPlaceReview = {
    ...mapPlaceReview,
    id: uid,
    uid: user.uid,
    isAnonymous: user.isAnonymous,
    name: user.isAnonymous ? "Anonymous" : user.displayName,
    created: serverTimestamp(),
    updated: serverTimestamp()
  };
  return await mapPlaceReviewRef(mapId, placeId, uid).set(newMapPlaceReview, {
    merge: true
  });
};

export const saveMap = async (mapId: string, mapData: Partial<Map>) => {
  analytics.logEvent("map_update", {
    map_id: mapId
  });
  return firestore
    .collection("maps")
    .doc(mapId)
    .set(
      {
        ...mapData,
        updated: serverTimestamp()
      },
      { merge: true }
    );
};

export const deleteMapPlace = async (mapId: string, placeId: string) => {
  analytics.logEvent("map_place_delete", {
    map_id: mapId,
    palce_id: placeId
  });
  return mapPlaceRef(mapId, placeId).delete();
};
