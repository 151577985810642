import React, { ChangeEvent, useState } from "react";
import { Box, Button, FormField, Text, TextInput } from "grommet";
import { useDebouncedCallback } from "use-debounce";
import LoadingBox from "./LoadingBox";

import { getPlacesAutocomplete } from "src/lib/places";

interface Props {
  onSelect: (place: google.maps.places.AutocompletePrediction) => void;
  options?: google.maps.places.AutocompletionRequest;
  saving?: boolean;
}

const PlaceSuggestionField: React.FC<Props> = ({
  onSelect,
  options,
  saving
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [places, setPlaces] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [selectedPlace, setSelectedPlace] = useState<
    google.maps.places.AutocompletePrediction
  >();

  const [debouncedCallback] = useDebouncedCallback(async (value: string) => {
    const response = await getPlacesAutocomplete(value, options);
    setPlaces(response);
    setLoading(false);
  }, 1000);

  const placeOnChange = ({
    currentTarget: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    debouncedCallback(value);
  };

  const placeOnSelect = (place: google.maps.places.AutocompletePrediction) => {
    setSelectedPlace(place);
    onSelect && onSelect(place);
  };

  return (
    <Box>
      <FormField label="Enter a country, city or address...">
        <TextInput
          onChange={placeOnChange}
          autoFocus={true}
          disabled={saving}
        />
      </FormField>
      {loading && <LoadingBox />}
      {places &&
        places.map(place => (
          <Box pad={{ top: "small" }} key={place.place_id}>
            <Button
              active={
                selectedPlace && selectedPlace.place_id === place.place_id
              }
              hoverIndicator={true}
              label={
                <Box pad="small">
                  <Text size="medium">
                    {place.structured_formatting.main_text}
                  </Text>
                  <Text size="small" color="dark-3">
                    {place.structured_formatting.secondary_text}
                  </Text>
                </Box>
              }
              color="dark-5"
              onClick={() => placeOnSelect(place)}
              disabled={saving}
            />
          </Box>
        ))}
    </Box>
  );
};

export default PlaceSuggestionField;
