import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Text } from "grommet";

interface Props {
  text?: string;
}

const LoadingBox: React.FC<Props> = ({ text }) => (
  <Box fill align="center" justify="center" pad="medium">
    <Text color="brand">
      <FontAwesomeIcon icon={faSpinner} spin size="2x" />
    </Text>
    {text && (
      <Text margin="small" size="large">
        {text}
      </Text>
    )}
  </Box>
);

export default LoadingBox;
