import React, { useContext } from "react";
import { faClock, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { Box, Button, ButtonType, ResponsiveContext, Text } from "grommet";
import styled from "styled-components";

import RatingFace from "src/components/RatingFace";

import MapPlaceReview from "src/models/MapPlaceReview";

interface Props extends ButtonType {
  review: MapPlaceReview;
}

const BoxIconWrapper = styled(Box)`
  width: 18px;
`;

const MapPlaceReviewItem: React.FC<Props> = ({ review, ...props }) => {
  const responsive = useContext(ResponsiveContext);
  return (
    <Button
      margin={{ bottom: "small" }}
      key={review.comment}
      color="light-5"
      style={{ background: "white" }}
      label={
        <Box
          justify="between"
          direction="row"
          pad={{ vertical: "medium" }}
          gap="small"
        >
          <Box flex direction="column" gap="xsmall">
            <Box direction="row" align="center">
              <BoxIconWrapper margin={{ right: "small" }} align="center">
                <FontAwesomeIcon icon={faUserCircle} />
              </BoxIconWrapper>
              <Text>{review.name}</Text>
            </Box>
            <Text color="dark-5" size="small">
              <Box direction="row" align="center">
                <BoxIconWrapper margin={{ right: "small" }} align="center">
                  <FontAwesomeIcon icon={faClock} />
                </BoxIconWrapper>
                <Text size="small">
                  {format(
                    review.created.seconds * 1000,
                    "hh:mm a - dd/MM/yyyy"
                  )}
                </Text>
              </Box>
            </Text>
            <Box align="start" pad={{ top: "medium" }}>
              {review.comment ? (
                <Text textAlign="start" size={responsive}>
                  {review.comment}
                </Text>
              ) : (
                <Text color="light-5" textAlign="start">
                  No comment
                </Text>
              )}
            </Box>
          </Box>
          <Box align="center" justify="center">
            <RatingFace value={review.rating} style={{ height: 32 }} />
          </Box>
        </Box>
      }
      {...props}
    />
  );
};

export default MapPlaceReviewItem;
