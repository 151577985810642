import React from "react";
import { Box } from "grommet";

interface PartnerStatusProps {
  status: string;
}

const PartnerStatus: React.FC<PartnerStatusProps> = ({ status }) => {
  let background;
  let color;
  switch (status) {
    case "new":
      background = "status-error";
      break;
    case "contacted":
      background = "status-warning";
      break;
    case "pending":
      background = "neutral-3";
      break;
    case "partner":
      background = "status-ok";
      break;
    case "closed":
      background = "dark-1";
      break;
  }

  return (
    <Box
      // style={{ display: "inline-flex" }}
      background={background}
      color={color}
      align="center"
      pad={{ vertical: "xsmall", horizontal: "small" }}
    >
      {status}
    </Box>
  );
};

export default PartnerStatus;
