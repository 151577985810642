import React, { useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Form, Heading, Layer, Text } from "grommet";
import LayerHeader from "./LayerHeader";
import PlaceSuggestionField from "./PlaceSuggesionField";

import { saveNewMap } from "src/lib/dartmap";
import history from "src/lib/history";
import { getPlace } from "src/lib/places";

interface Props {
  onHide: () => void;
}

const NewMapLayer: React.FC<Props> = ({ onHide }) => {
  const [saving, setSaving] = useState<boolean>(false);

  const [selectedPlace, setSelectedPlace] = useState<
    google.maps.places.AutocompletePrediction
  >();

  const save = async () => {
    if (saving || !selectedPlace || !selectedPlace.place_id) {
      return;
    }
    setSaving(true);
    const place = await getPlace(selectedPlace.place_id);
    const { lat = 0, lng = 0 } = place.geometry
      ? place.geometry.location.toJSON()
      : {};
    const newMap = await saveNewMap({
      address: place.formatted_address,
      lat,
      lng,
      placeId: selectedPlace.place_id,
      tags: []
    });
    if (newMap && newMap.id) {
      history.push(`/map/${newMap.id}`);
    }
  };

  return (
    <Layer onEsc={onHide} onClickOutside={onHide} margin="large">
      <Box fill overflow="auto">
        <LayerHeader title="Where are you going?" onHide={onHide} />
        <Box pad="large">
          <Form onSubmit={save}>
            <PlaceSuggestionField onSelect={setSelectedPlace} saving={saving} />
            <Box pad={{ vertical: "large" }}>
              <Button
                type="submit"
                primary
                label={
                  <Box pad="small" align="center">
                    {saving ? (
                      <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
                    ) : (
                      <Text>Submit</Text>
                    )}
                  </Box>
                }
                disabled={!selectedPlace || saving}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </Layer>
  );
};

export default NewMapLayer;
