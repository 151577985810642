import React, { ReactNode, useContext } from "react";
import {
  faDoorOpen,
  faMapMarkedAlt,
  faSignInAlt,
  faSpinner,
  faUserCircle,
  faUserLock,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Image, ResponsiveContext, Text } from "grommet";
import { Link } from "react-router-dom";
import { AppContext } from "./context/AppContext";

interface Props {
  right?: ReactNode;
}

const TopNav: React.FC<Props> = ({ right }) => {
  const { user, userLoading, setShowAuthLayer } = useContext(AppContext);

  const defaultRight = userLoading ? (
    <Button hoverIndicator icon={<FontAwesomeIcon icon={faSpinner} spin />} />
  ) : user ? (
    <Link to="/profile" style={{ color: "black" }}>
      <Button hoverIndicator icon={<FontAwesomeIcon icon={faUserCircle} />} />
    </Link>
  ) : (
    <Button
      hoverIndicator
      icon={<FontAwesomeIcon icon={faUserLock} />}
      onClick={() => setShowAuthLayer(true)}
    />
  );

  return (
    <Box direction="row" justify="between" align="center" pad="medium">
      <Box>
        <Link to="/" style={{ color: "black" }}>
          <Button
            hoverIndicator
            icon={<FontAwesomeIcon icon={faMapMarkedAlt} />}
          />
        </Link>
      </Box>
      <Box>{right ? right : defaultRight}</Box>
    </Box>
  );
};
export default TopNav;
