import React, { useContext } from "react";
import { faAlignLeft, faMap, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ResponsiveContext, Text } from "grommet";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Map from "src/models/Map";

interface Props {
  map: Map;
  currentTab: string;
}

const CustomButton = styled(Button)<{ small?: boolean }>`
  ${({ small }) =>
    small &&
    `
    padding: 4px 12px;
  `}
`;

const MapPageTabButtons: React.FC<Props> = ({ map, currentTab }) => {
  const responsive = useContext(ResponsiveContext);
  const small = responsive === "small";
  return (
    <Box
      pad={{ vertical: "large" }}
      align="center"
      justify="center"
      direction="row"
      gap={small ? "xsmall" : "medium"}
      style={{ overflowX: "auto", overflowY: "hidden" }}
    >
      <Link to={`/map/${map.id}/details`}>
        <CustomButton
          small={small}
          active={currentTab === "details"}
          label={<Text truncate>About</Text>}
          icon={<FontAwesomeIcon icon={faAlignLeft} />}
        />
      </Link>
      <Link to={`/map/${map.id}/map`}>
        <CustomButton
          small={small}
          active={currentTab === "map"}
          label={<Text truncate>Map</Text>}
          icon={<FontAwesomeIcon icon={faMap} />}
        />
      </Link>
      <Link to={`/map/${map.id}/places`}>
        <CustomButton
          small={small}
          active={currentTab === "places"}
          label={<Text truncate>Places</Text>}
          icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
        />
      </Link>
    </Box>
  );
};

export default MapPageTabButtons;
