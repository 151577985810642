import React, { createContext, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { Profile } from "src/models/Profile";

import { profileRef } from "src/lib/dartmap/refs";
import { analytics, auth } from "src/lib/firebase";

import { defaultLayerMap, LayerId, LayerMap } from "src/types/LayerMap";

interface AppContextProps {
  user?: firebase.User;
  userLoading: boolean;
  profile?: Profile;
  profileLoading: boolean;
  showAuthLayer: boolean;
  setShowAuthLayer: (show: boolean) => void;
  showUsernameLayer: boolean;
  setShowUsernameLayer: (show: boolean) => void;
  showLayer: (id: LayerId, data: any) => void;
  hideLayer: (id: LayerId) => void;
  layerMap: LayerMap;
}

export const AppContext = createContext<AppContextProps>({
  userLoading: true,
  showAuthLayer: false,
  profileLoading: true,
  setShowAuthLayer: () => {},
  showUsernameLayer: false,
  setShowUsernameLayer: () => {},
  showLayer: () => {},
  hideLayer: () => {},
  layerMap: defaultLayerMap
});

const AppContextWrapper: React.FC = ({ children }) => {
  const [showAuthLayer, setShowAuthLayer] = useState<boolean>(false);
  const [showUsernameLayer, setShowUsernameLayer] = useState<boolean>(false);
  const [layerMap, setLayerMap] = useState<LayerMap>(defaultLayerMap);
  const [user, userLoading] = useAuthState(auth);

  const uid = user ? user.uid : "fake";

  const [profile, profileLoading] = useDocumentData<Profile>(profileRef(uid));

  const onShowAuthLayer = (show: boolean) => {
    analytics.logEvent("layer_auth", { show });
    setShowAuthLayer(show);
  };

  const onShowUsernameLayer = (show: boolean) => {
    analytics.logEvent("layer_username", { show });
    setShowUsernameLayer(show);
  };

  const setLayer = (id: LayerId, visible: boolean, data: any = {}) => {
    setLayerMap({
      ...layerMap,
      [id]: {
        visible,
        data
      }
    });
  };

  const showLayer = (id: LayerId, data: any) => setLayer(id, true, data);
  const hideLayer = (id: LayerId) => setLayer(id, false);

  const providerValue = {
    user,
    userLoading,
    profile,
    profileLoading,
    showAuthLayer,
    setShowAuthLayer: onShowAuthLayer,
    showUsernameLayer,
    setShowUsernameLayer: onShowUsernameLayer,
    showLayer,
    hideLayer,
    layerMap
  };

  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
};

export default AppContextWrapper;
