import React, { ReactNode } from "react";
import { Box, Header, Heading } from "grommet";
import Helmet from "react-helmet";
import TopNav from "./TopNav";

interface Props {
  title?: string;
  header?: ReactNode;
  noHeader?: boolean;
  topNavRight?: ReactNode;
}

const Page: React.FC<Props> = ({
  title,
  header,
  children,
  topNavRight,
  noHeader
}) => (
  <Box fill>
    <TopNav right={topNavRight} />
    <Helmet>
      <title>{title || "Dartmap"}</title>
    </Helmet>
    {noHeader !== true && (
      <Header pad={{ vertical: "small", horizontal: "medium" }} gap="small">
        {header || (
          <Heading level={2} margin="0" truncate style={{ padding: "2px 0px" }}>
            {title}
          </Heading>
        )}
      </Header>
    )}

    {children}
  </Box>
);

export default Page;
